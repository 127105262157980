
import errorsMixin from '../mixins/error-handler';

export default {
  name: 'SLocInput',
  mixins: [errorsMixin],
  props: {
    sharedError: {
      type: Object,
      default: () => ({}),
    },
    items: {
      type: Array,
      default: () => [],
    },
    selected: {
      default: null,
      type: Object,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    menuProps: {
      type: Object,
      default: null,
    },
    showLocDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: null,
      hideSelect: false,
      dialogLoc: false,
      dialogLocOptions: {
        contentClass: 'localisation-dialog',
      },
      dialogLocTitle: 'Votre localisation',
      dialogLocLabel: 'Votre localisation',
      justClosed: false,
    };
  },
  computed: {
    select: {
      get() {
        return this.selected;
      },
      set() {},
    },
    searchIsValid() {
      // The search is set and contains between 1 and 100 characters
      return this.search && this.search.length > 0 && this.search.length <= 100;
    },
  },
  watch: {
    dialogLoc() {
      if (this.dialogLoc) {
        this.$emit('search', null);
        this.$emit('update:items', []);
        // Put the focus on the text input
        setTimeout(() => {
          this.$refs.dialogSearchInput.focus();
        }, 250);
      } else if (this.selected) {
        this.$emit('update:items', [this.selected]);
      } else {
        this.errors$.handleError(false, 'geo', 'search');
      }
    },
    sharedError() {
      this.initErrors();
    },
    selected(value) {
      if (value) {
        this.errors$.handleError(false, 'geo', 'search');
      }
    },
    search() {
      // When the search changes, if it is not set or if it is valid, set the error to false
      if (!this.search || this.searchIsValid) {
        this.errors$.handleError(false, 'geo', 'search');
      }
    },
  },
  mounted() {
    if (!this.showLocDialog && !this.select) {
      this.$refs['geo-search'].focus();
    }
    this.initErrors();
    document.addEventListener('focusin', this.focusIn);
  },
  beforeDestroy() {
    document.removeEventListener('focusin', this.focusIn);
  },
  methods: {
    focusIn(event) {
      const el = event.target;
      if (el === this.$refs.locInput && !this.errors$.hasErrors()) {
        if (this.showLocDialog) {
          // Remove focus from any focused element
          if (document.activeElement) {
            document.activeElement.blur();
          }
          this.openDialog();
        } else {
          // Put the focus on the autocomplete
          // Need a timeout to avoid that the focus is immediately removed
          setTimeout(() => {
            this.$refs['geo-search'].focus();
          }, 250);
        }
      }
    },
    initErrors() {
      this.errors$.mergeErrors(this.sharedError);
      if (this.errors$.getAll('geo')) {
        const keys = Object.keys(this.errors$.getAll('geo'));
        if (keys.length) {
          this.$nextTick(() => {
            this.$refs[`geo-${keys[0]}`].$el.scrollIntoView({ behavior: 'smooth', block: 'center' });
          });
        }
      }
    },
    // AUTOCOMPLETE ------------------------------------------------------------------------------------
    onFocus() {
      // Don't open the dialog box when the focus is on the autocomplete component
      // right after the dialog box is closed
      if (this.justClosed) return;

      if (this.showLocDialog) {
        this.openDialog();
      } else {
        this.hideSelect = true;
        this.$emit('update:items', []);
        if (this.select) {
          this.search = this.select.inputText;
          this.$nextTick(() => {
            this.$emit('update:items', [this.select]);
          });
        }
      }
    },
    onBlur(event) {
      if (!this.showLocDialog && this.select && this.select.inputText !== this.search) {
        this.$emit('update:items', [this.select]);
      }

      const el = event.relatedTarget;
      if (el !== this.$refs.locInput) {
        setTimeout(() => {
          this.search = null;
          this.hideSelect = false;
        }, 250);
      }
    },
    onInput(value) {
      if (!this.showLocDialog) {
        this.$refs['geo-search'].blur();
        this.$emit('update:selected', value);
      }
    },
    onClickAppend() {
      if (this.showLocDialog) {
        this.openDialog();
      } else if (this.hideSelect) {
        this.$refs['geo-search'].blur();
      } else {
        this.$refs['geo-search'].focus();
        this.$refs['geo-search'].activateMenu();
      }
    },
    onClear() {
      this.$emit('update:selected', null);
      if (this.$refs['geo-search']) {
        setTimeout(() => {
          this.$refs['geo-search'].blur();
        }, 0);
      }
    },
    // DIALOG BOX --------------------------------------------------------------------------------------
    openDialog() {
      if (this.showLocDialog && !this.disabled) {
        setTimeout(() => {
          this.dialogLoc = true;
        }, 0);
      }
    },
    onInputDialog(search) {
      this.errors$.handleError(!!search && search.length > 0 && search.length > 100, 'geo', 'search');
      this.$emit('search', search);
    },
    onChangeDialog(value) {
      this.dialogLoc = false;
      this.$emit('update:selected', value);
    },
    dialogLocClosed() {
      // The dialog box has just been closed
      this.justClosed = true;
      this.$nextTick(() => {
        // Remove the focus from the autocomplete.
        this.$refs['geo-search'].blur();
        // Reset variable
        this.justClosed = false;
      });
    },
  },
};
